.card-header {
  margin-bottom: 16px;
}

.GoodFood {
  width: 110px;
  margin: auto;
  margin-top: 8px;
  margin-bottom: -10px;
}
.Meta {
  width: 90px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: -20px;
}
.Ubisoft {
  width: 95px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: -12px;
}
.Stealth {
  width: 70px;
  margin: auto;
  margin-top: 0px;
  margin-bottom: -12px;
}
.LinkedIn3 {
  width: 115px;
  margin: auto;
  margin-top: -10px;
  margin-bottom: -40px;
}
.omarlogo {
  width: 95px;
  margin: auto;
  margin-top: -10px;
  margin-bottom: -25px;
}
.Intern {
  font-size: 12px;
  margin: 0px 230px 0px 230px;
}

@media (max-width: 960px) {
  #Experience {
    margin: 20px;
    width: 552px;
  }
  .Intern {
    margin: auto;
  }
}
@media (max-width: 600px) {
  #Experience {
    width: 90%;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-right: 10px;
  }
}
#Experience {
  background-color: white;
  max-width: 912px;
  height: fit-content;
  border: 0px solid gray;
  margin-top: 20px;
  font-size: 16px;
  color: black;
  padding: 18px 24px;
  text-align: center;
  box-shadow: 0 6px 9px rgb(0 0 0 / 0.2);
  margin: 25px auto;
}
.Done {
  text-align: left;
}
.ul {
  text-align: left;
}
.li {
  text-align: left;
}
