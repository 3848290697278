.footer {
  text-align: center;
  padding: 7px;
  position: relative;
  bottom: 0;
  width: 100%;
}

.footer p {
  color: #888888;
}
.footer a {
  color: green;
}
