.overlay {
  font-size: 22px;
  padding: 0;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 100;
  pointer-events: none;
  width: 100%;
  height: 100%;
}

.burger {
  display: inline-block;
  cursor: pointer;
  position: relative;
  z-index: 102;
}

.MyName {
  margin: 20px;
  cursor: pointer;
}

:root {
  --text-color: #dadce1;
  --border: 1px solid #474a4d;
  --border-radius: 8px;
  --speed: 500ms;
}

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--text-color);
  text-decoration: none;
}

/* Top Navigation Bar */

/* <nav> */
.navbar {
  height: var(--nav-size);
  padding: 0 1rem;
  position: relative;
  z-index: 103;
}

/* <ul> */
.navbar-nav {
  max-width: 100%;
  height: 100%;
  display: flex;
}

/* <li> */
.nav-item {
  width: calc(var(--nav-size) * 0.8);
  display: flex;
  align-items: flex-start;
  justify-content: center;
  margin-left: 16px;
  position: relative;
  z-index: 102;
  height: auto;
}

/* Icon Button */
.icon-button {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  color: red;
  margin-right: 5px;
  margin-top: 25px;
  display: flex;
  align-items: center;
  position: fixed;
  top: 0;
  left: 16px;
  justify-content: center;
  transition: filter 300ms;
  cursor: pointer;
  pointer-events: auto;
  transform: none;
}
.icon-button-inside {
  --button-size: calc(var(--nav-size) * 0.5);
  width: var(--button-size);
  height: var(--button-size);
  background-color: #484a4d;
  border-radius: 50%;
  padding: 5px;
  margin-right: 5px;
  display: flex;
  align-items: center;
  color: white;
  justify-content: center;
}

.icon-button:hover {
  filter: brightness(1.2);
  text-decoration: none;
  color: black;
  /* margin-top: 10px;    it would be coool if i can add a margin to them    it would like nicee*/
}

.icon-button svg {
  fill: var(--text-color);
  width: 20px;
  height: 20px;
}

/* Dropdown Menu */

.dropdown {
  position: fixed;
  background-color: white;
  top: 70px;
  left: -47px;
  width: 100%;
  box-shadow: 8px 8px 8px 0px rgb(0 0 0 / 0.2);
  padding: 1rem;
  overflow: hidden;
  transition: all var(--speed) cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  transform: translateY(-100%);
  animation: slideDown 0.3s ease-out forwards;
  z-index: 101;
  pointer-events: auto;
  height: auto !important;
}

.menu {
  width: 100%;
  color: black;
  max-width: 912px;
  margin: 0 auto;
  padding: 0 24px;
  pointer-events: auto;
}

.menu-item {
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: var(--border-radius);
  transition: background var(--speed);
  padding: 0.5rem;
  text-decoration: none;
  color: black;
  cursor: pointer;
  pointer-events: auto;
}

.menu-item .icon-button {
  margin-right: 0.5rem;
}

.menu-item .icon-button:hover {
  filter: none;
  text-decoration: none;
  color: black;
}

.menu-item:hover {
  background-color: rgb(0 255 0 / 0.7);
  text-decoration: none;
}

.icon-right {
  margin-left: auto;
}

/* CSSTransition classes  */
.menu-primary-enter {
  position: absolute;
  transform: translateX(-110%);
}
.menu-primary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}
.menu-primary-exit {
  position: absolute;
}
.menu-primary-exit-active {
  transform: translateX(-110%);
  transition: all var(--speed) ease;
}

.menu-secondary-enter {
  transform: translateX(110%);
}
.menu-secondary-enter-active {
  transform: translateX(0%);
  transition: all var(--speed) ease;
}

.menu-secondary-exit-active {
  transform: translateX(110%);
  transition: all var(--speed) ease;
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (min-width: 960px) {
  .dropdown {
    display: none;
  }

  .burger {
    display: none;
  }
  .nav-item {
    display: none;
  }
}
