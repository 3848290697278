.card-header {
  margin-bottom: 16px;
}

.FindOmar {
  width: 140px;
  margin: auto;
  margin-top: 16px;
}
.Projects {
  font-size: 12px;
  margin: 0px 230px 0px 230px;
}
@media (max-width: 960px) {
  #Project {
    margin: 20px auto;
    width: 552px;
  }

  .Projects {
    margin: auto;
  }
}

@media (max-width: 600px) {
  #Project {
    width: 90%;
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border-right: 10px;
  }
}
#Project {
  background-color: white;
  max-width: 912px;
  height: fit-content;
  border: 0px solid gray;
  font-size: 16px;
  color: black;
  padding: 18px 24px;
  text-align: center;
  box-shadow: 0 6px 9px rgb(0 0 0 / 0.2);
  margin: 25px auto;
}

li::before {
  content: "\2022";
  color: green;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

.description {
  text-align: left;
  margin-left: 20px;
}
