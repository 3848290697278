@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  /* margin: 0px;
  padding: 0px; */
  box-sizing: border-box;
}

.a {
  width: max-content;
}

/* .p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em
  margin-inline-start: 0px;
  margin-inline-end: 0px;
} */
