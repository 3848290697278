@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");

* {
  /* margin: 0px;
  padding: 0px; */
  box-sizing: border-box;
  font-family: "Raleway" !important;
}

body {
  position: relative;
  background-image: url("./imgs/Wallpaper.png");
  background-repeat: repeat;
}

html {
  scroll-behavior: smooth;
  font-family: "Raleway";
}

.center {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 960px) {
}
