#finally {
  text-align: center;
}
#MyCards {
  position: center;
  margin: 16px;
  text-align: left;
}

#ToTheLeft {
  height: 65px;
  text-align: left;
}
#sa {
  text-align: center;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  /* grid-template-columns: repeat(2, 350px); */
  grid-row: auto;
  grid-column-gap: 60px;
  grid-row-gap: 50px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 960px) {
  #MyCards {
    flex-basis: 50%;
  }
}
@media (max-width: 960px) {
  #sa {
    display: grid;
    grid-row: auto;
    flex-basis: 50%;
    grid-row-gap: 50px;
    grid-template-columns: 1fr;
  }
}
.M1 {
  width: 100%;
}
