
// button 1 -> resume
    
    *
    box-sizing: border-box
    
#myClass2
    
    // top: 54%
    // margin-right: 400px
    // width: 110px
    // right:100px
    // align-items: center
    // background-color: red
    // color: red
    // display: flex
    // font-family: 'Roboto'
    // font-size: 10px
    // height: 100%
    // justify-content: center
    // margin: 0
    // padding: 0
    // width: 100%


#gooey-button 
    
    position: absolute
    top: 54%
    background-size: cover
    right: -300px
    margin-right: 400px
    max-width: 430px
    transform: translateY(-50%) translateX(-50%)
    z-index: 2
    filter: drop-shadow(0 0 40px rgba(0, 0, 0, 0.3))

    width: 110px
    height: 45px
    font-size: 20px
    border: none
    filter: url('#gooey')
    background-color: #61ca37
    //linear-gradient(to right, rgb(93, 238, 93), #61ca37)
    // @media (max-width: 960px) 
    // defs
    //     display: none
  

    &:focus
        outline: none

    .bubbles
        position: absolute
        top: 0
        left: 0
        bottom: 0
        right: 0.1

        .bubble
            background-color:  #61ca37
            border-radius: 100%
            position: absolute
            top: 0
            left: 0
            display: block
            z-index: -1

            @for $bubble from 1 through 10
                &:nth-child(#{$bubble})
                    $size: 25px
                    left: (random(90) + 8)+px
                    width: $size
                    height: $size
                    animation: move-#{$bubble} #{4 + $bubble*0.02}s infinite
                    animation-delay: #{$bubble*0.2}s
                
@for $bubble from 1 through 10
    @keyframes move-#{$bubble}
        0%
            transform: translate(0, 0)
        99%
            transform: translate(0, -(random(80) + 50)+px)
        100%            
            transform: translate(0, 0)
            opacity: 0





    